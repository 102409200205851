<template>
  <div class="loading-page-root">
      <!-- <div class="dot-loader"><div></div><div></div><div></div><div></div></div> -->
      <div class="das-loader">&nbsp;</div>
      <!-- <span style="font-size: 12px;">We've got something we think you'll love</span> -->
      <!-- <span>Strike Fast. Market Well. Sell More.</span> -->
      <span style="margin-top: 20px; font-weight: 500;">Sit tight, we're getting your program details!</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
$color: #545454;

.loading-page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    color: $color;
}

.das-loader {
  width: 100px;
  height: 100px;
  background-image: url('~@/assets/images/crosshair.png');
  background-position: center;
  background-size: contain;
  animation: 2.5s spin cubic-bezier(.31,-0.6,.76,1.46) infinite;
}

@keyframes spin {
    0% {
        transform:rotate(0deg);
    }
    70% {
        transform:rotate(1080deg);
    }

    100% {
        transform:rotate(1080deg);
    }
}

// Usage: <div class="dot-loader"><div></div><div></div><div></div><div></div></div>
.dot-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.dot-loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $color;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.dot-loader div:nth-child(1) {
  left: 8px;
  animation: dot-loader1 0.6s infinite;
}
.dot-loader div:nth-child(2) {
  left: 8px;
  animation: dot-loader2 0.6s infinite;
}
.dot-loader div:nth-child(3) {
  left: 32px;
  animation: dot-loader2 0.6s infinite;
}
.dot-loader div:nth-child(4) {
  left: 56px;
  animation: dot-loader3 0.6s infinite;
}
@keyframes dot-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes dot-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes dot-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// Usage: <div class="heart-loader"><div></div></div>
.heart-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.heart-loader div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: rgb(255, 145, 0);
  animation: heart-loader 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.heart-loader div:after,
.heart-loader div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: rgb(255, 145, 0);
}
.heart-loader div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.heart-loader div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes heart-loader {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

</style>