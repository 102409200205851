<template>
<div class="step-1-gift-root">
    <div class="step-1-gift-image-container">
        <img class="step-1-gift-image-overlay" :src="imageSrc" />
    </div>
    <div class="step-1-gift-text-container">
        <div>
            <img v-if="headerImageSrc" :src="headerImageSrc" style="width: 100%; max-width: 60px;"/>
            <span v-else>{{ headerFallbackText }}</span>
        </div>
        <div class="step-1-gift-text-footer">
            {{ footerText }}
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true
    },
    headerImageSrc: {
      type: String,
      required: false
    },
    headerFallbackText: {
      type: String,
      required: true
    },
    footerText: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.step-1-gift-root {
    
}

.step-1-gift-image-container {
    width: 90px;
    height: 90px;
    background-color: white;
    border: 2px solid #FFA500;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-1-gift-image-overlay {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.15);
}

.step-1-gift-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.step-1-gift-text-header {

}

.step-1-gift-text-footer {
    font-size: 10px;
}

@media (min-width: $media-min-width) {

//     .step-1-gift-root {
    
// }

.step-1-gift-image-container {
    width: 130px;
    height: 130px;
}

// .step-1-gift-image-overlay {

// }

// .step-1-gift-text-container {

// }

// .step-1-gift-text-header {

// }

// .step-1-gift-text-footer {

// }

}
</style>