<template>
  <div class="thank-you-page-root">
    <div class="outer-container">
      <img class="header-image" src="@/assets/images/das-logo-colored.png" alt="Thank You">
      <div style="display:flex; justify-content: center; align-items: center; column-gap: 20px; width: 100%;">
        <img src="@/assets/images/gm-logo.png" alt="GM" style="width: 33%; max-width: 75px;">
        <img src="@/assets/images/imr-logo.png" alt="IMR" style="width: 33%; max-width: 250px;">
        <img src="@/assets/images/dds-logo.png" alt="DDS" style="width: 33%; max-width: 250px;">
      </div>

      <div class="main section">
        <div class="pyro">
          <div class="before"></div>
          <div class="after"></div>
        </div>

        <div class="next-steps section">
        <div class="next-steps-heading">Next Steps...</div>
        <div class="point" data-num="1">To bill via IMR and get your entries to win an exclusive once-in-a-lifetime luxury trip to London, a BVLGARI necklace, and much more, you MUST enroll via GM Global connect by clicking <a href="https://www.autopartners.net/gmentsso/UI/Login?goto=https%3A%2F%2Fdealer.autopartners.net%3A443%2Fsites%2Foe%2FPages%2FHome.aspx#/">HERE</a>.
          <!-- <ul>
            <li>Make sure to select the {{ gmPackageEquivalent }}</li>
            <li>Screenshot your submission and send it to your Client Advocate</li>
            <li>If you select DAS and your correct packages by the END OF NOVEMBER, you get to enter BOTH Richard Branson's Private Estate AND the BIG GAME! Send your Client Advocate a screenshot of your enrollment to LOCK IN your entries!</li>
          </ul> -->
        </div>
        <div class="point" data-num="2">Once your enrollment is received from the GM team, your dedicated client advocate will be reaching out to you to review your onboarding timeline for your new features and appropriate next steps to get you set up for success!</div>
      </div>

      <div class="thank-you section">
        <h1 class="main-heading">Thank you for partnering with Digital Air Strike.</h1>
        <div class="sub-text">We understand that time is money for dealerships. That's why our cutting edge Social Media and Reputation Management solutions are designed to save you time so you can focus on what you do best – selling cars!<br><br>You copy of your General Motors Social Media and Reputation Management agreement with Digital Air Strike is attached.</div>
        <div class="button-container">
          <a :href="$store.state.opportunity.pdfUrl" download="" v-if="pdfUrlValid"><button class="download-button" :class="[ pdfUrlValid ? '' : 'disabled' ]">DOWNLOAD</button></a>
          <button class="download-button disabled" v-else>Call us</button>
        </div>
      </div>
    </div>

      <div class="thank-you-image section">
        <img src="@/assets/images/thank-you-photo.jpg" alt="DAS with Sir Richard Branson">
        <div class="image-subtext">In addition to our award-wining solutions, every new GM dealer that partners with DAS during this promotional period is entered to win a once-in-a-lifetime luxury trip to London to stay in the BVLGARI Hotel. The winner will also receive a stunning BVLGARI necklace benefitting Save The Children. Winner announced on Valentine's Day 2025. Ask your Client Advocate for details.</div>
      </div>

      <div class="footer section">
        <img src="@/assets/images/dealers-choice-award.png" alt="Dealer's Choice">
        <div class="footer-text">
          <div class="footer-header">Your Unfair Online Advantage™</div>
          <div class="footer-subtext">Top dealers named Digital Air Strike the <b>Top Choice for Reputation Management, Social Media Management, and Digital Marketing</b> because of the great results we deliver to over 9,200 retailers, partners and OEMs.</div>
          <a class="footer-link" :href="$store.state.links.sfe_package_site" target="_blank">{{ $store.state.links.sfe_package_site }}</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage',
  components: {
    
  },
  methods: {

  },
  computed: {
    pdfUrlValid() {
      return this.$store.state.opportunity.pdfUrl ? true : false;
    },
    gmPackageEquivalent() {
      // In the event that the requisite object(s) are null or empty, return a default value
      if(!this.$store.state?.opportunity?.newPackageName)
        return "equivalent GM package"

      var packageName = this.$store.state?.opportunity?.newPackageName?.toUpperCase();

      if (packageName.includes("SELL"))
        return "Managed Premium Social Package";
      else if (packageName.includes("ENGAGE"))
        return "Managed Social Package";
      else if (packageName.includes("RESPOND"))
        return "Managed Response Package";
      else
        return "equivalent GM package";
    }
  },
  mounted() {
    window.scrollTo(0,0); // Because we're not reloading into a new page (just changing the view), we need to scroll to the top of the window to start at the top of the thank you page.
  }
}
</script>

<style lang="scss" scoped>
ul > li {
  padding: 5px 0px 5px 0px;
}

.thank-you-page-root {
  overflow: hidden;
  background-color: white;
}

.header-image {
  width: 90%;
  max-width: 538px;
  object-fit: cover;
  margin-top: 25px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:max-content;
  box-sizing: border-box;
}

.main {
  position: relative;
}

.main > .main-heading {
  width: 100%;
  color: white;
  font-size: clamp(28px, 5vw, 48px);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
}

.main > .sub-text {
  width: 100%;
  color: #FFFFFF;
  font-size: clamp(20px, 3.5vw, 26px);
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.main > .button-container {
  margin-top: 50px;
}

.download-button {
  border-radius: 4px;
  background-color: #0096DB;
  color: white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border: none;
  margin-top: 40px;
}

.disabled {
  background-color: grey;
  opacity: 0.3;
}

.next-steps {
  color: #4D4D4D;
  padding: 25px;
}

.next-steps > .next-steps-heading {
  color: #DD6000;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
  margin-bottom: 20px;
}

.next-steps > .point {
  width: 100%;
  font-size: clamp(16px, 4vw, 26px);
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 25px;
}

.next-steps > .point::before {
  color: #DD6000;
  content: attr(data-num) ". ";
}

.thank-you {
  background-color: #0a286d;
  color: #ffffff;
  padding: 25px;
}

.thank-you > .main-heading {
  width: 85%;
  font-size: clamp(28px, 5vw, 48px);
  text-align: center;
}

.thank-you > .sub-text {
  text-align: center;
}

.thank-you-image {
  padding: 0px;
  background-color: #DD6000;
}

.thank-you-image > img {
  width: 100%;
  object-fit: cover;
}

.thank-you-image a:link,
.thank-you-image a:visited,
.thank-you-image a:hover,
.thank-you-image a:active {
  color: white;
}

.thank-you-image > .image-subtext {
  width: 85%;
  height: max-content;
  color: white;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer {
  color: #4D4D4D;
  padding-top: 10px;
  padding: 0px;
  margin-top: 20px;
  padding-bottom: 50px;
}

.footer > img {
  width: 90%;
  object-fit: cover;
  
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  margin-top: 50px;
  text-align: center;
}

.footer-header {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center;
}

.footer-subtext {
  width: 77%;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.footer-link {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  color: #0096DB;
  text-decoration: none;
}

@media (min-width: $media-min-width) {
  .section {
    padding: 0px;
  }

  .main > .main-heading {
    width: 80%;
  }

  .main > .sub-text {
    width: 85%;
  }

  .next-steps {
    padding: 35px;
  }

  .next-steps > .point {
    width: 85%;
  }
  
  .thank-you {
    padding: 30px 35px 70px 35px;
  }

  .thank-you > .sub-text {
    font-size: 26px;
    width: 85%;
    text-align: center;
  }

  .thank-you-image {
    padding: 0px;
  }

  .thank-you-image > .image-subtext {
    width: 85%;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .footer {
    padding-top: 0px;
  }
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-$width / 2 + px
               random($height)-$height / 1.2 + px
               hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}


.pyro {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none; 
}


.pyro > .before, .pyro > .after {
  $repetitions: 2;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out $repetitions backwards, 1s gravity ease-in $repetitions backwards, 5s position linear $repetitions backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    top: 10%;
    left: 80%;
  }
  20%, 39.9% {
    top: 40%;
    left: 30%;
  }
  40%, 59.9% {  
    top: 20%;
    left: 70%
  }
  60%, 79.9% {  
    top: 30%;
    left: 20%;
  }
  80%, 99.9% {  
    top: 30%;
    left: 80%;
  }
}

</style>