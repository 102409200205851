<template>
  <div class="submit-section-root">
      <div class="grid-container">
          <div class="backdrop"></div>
          <div class="content">
            <div class="form-container">
                <div>
                    <p>
                        <input type="checkbox" id="tou-checkbox" v-model="termsAndConditions" />
                        <label for="tou-checkbox" style="color: white;">I accept the terms and conditions and I am authorized to enter this agreement for the dealership identifier herein.</label>
                    </p>

                    <p>
                        <input type="checkbox" id="bill-imr-checkbox" v-model="billImr" />
                        <label for="bill-imr-checkbox" style="color: white;">Bill through IMR</label>
                    </p>

                    <p>
                        <input type="checkbox" id="authorized-user-checkbox" v-model="authorizedSigner" />
                        <label for="authorized-user-checkbox" style="color: white;">I am an authorized signer for this dealership</label>
                    </p>
                </div>

                <div class="input-main">
                    <div class="input-container">
                        <label for="sign-input">Sign</label>
                        <div style="display: flex; flex-direction: row; position: relative;">
                            <div class="signature-preview-container" @click.prevent="$store.dispatch('showSignatureModal')" :style="{ '--display-sign-form': $store.state.signature.completed ? 'none' : 'block' }">
                                <img :src="$store.state.signature.data" alt="Signature" v-if="$store.state.signature.type == 'draw'" >
                                <div class="adopt-signature-preview" v-else>{{ $store.state.signature.data }}</div>
                            </div>
                            <div class="clear-signature-button" @click="clearSignature" v-if="$store.state.signature.completed"><i class="fas fa-times"></i></div>
                        </div>
                        
                    </div>

                    <div class="input-container">
                        <label for="name-input">Printed Name</label>
                        <input type="text" id="name-input" v-model="signaturePrintedName">
                    </div>

                    <div class="input-container">
                        <label for="title-input">Title</label>
                        <input type="text" id="title-input" v-model="signatoryTitle">
                    </div>

                    <button class="sign-button" @click="submitContract()">SUBMIT</button>
                </div>
            </div>

            <div style="display: flex; justify-content: center; width: 100%;">
                <img src="@/assets/images/quote-image-new3.png" alt="Digital Air Strike" style="max-width: 100%;">
            </div>

            <div style="display: flex; width: 100%; color: white; font-weight: bold;">
                <div style="width: 50%; display: flex; justify-content: flex-start;">Your Unfair Online Advantage.™</div>
                <div style="width: 50%; display: flex; justify-content: flex-end;" class="das-link"><a href="https://www.digitalairstrike.com/" target="_blank">DigitalAirStrike.com</a></div>
            </div>

            <div style="width: 100%; height: 20px; background: #dd6000"></div>
              
          </div>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
        clearSignature() {
            // this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-SignatureCleared" });
            this.$store.dispatch("updateSignature", { completed: false, type: "", data: "" });
        },
        submitContract() {
            //this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-DocumentSubmitted" });
            this.$store.dispatch('validateAndSubmitContract')
        }
    },
    computed: {
        termsAndConditions: {
            get() {
                return this.$store.state.opportunity.tou_Accepted;
            },
            set(value) {
                // this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-TermsAndConditionsChecked", eventPayload: { enabled: value } });
                this.$store.dispatch("updateOpportunity", { prop: "tou_Accepted", value: value})
            }
        },
        billImr: {
            get() {
                return this.$store.state.opportunity.billImr;
            },
            set(value) {
                // this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-BillIMRChecked", eventPayload: { enabled: value } });
                this.$store.dispatch("updateOpportunity", { prop: "billImr", value: value})
            }
        },
        authorizedSigner: {
            get() {
                return this.$store.state.opportunity.authorizedSigner;
            },
            set(value) {
                // this.$store.dispatch("sendFullStoryEvent", { FS: this.$FullStory, eventName: "SFE-AuthorizedSignerChecked", eventPayload: { enabled: value } });
                this.$store.dispatch("updateOpportunity", { prop: "authorizedSigner", value: value})
            }
        },
        signaturePrintedName: {
            get() {
                return this.$store.state.opportunity.signaturePrintedName;
            },
            set(value) {
                this.$store.dispatch("updateOpportunity", { prop: "signaturePrintedName", value: value})
            }
        },
        signatoryTitle: {
            get() {
                return this.$store.state.opportunity.signatoryTitle;
            },
            set(value) {
                this.$store.dispatch("updateOpportunity", { prop: "signatoryTitle", value: value})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.submit-section-root {
    width: 100%;
}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(18, minmax(0px, max-content));
}

.backdrop {
    grid-area: 1 / 1 / 19 / 13;
    background: #4d4d4d;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;
}

.content {
    grid-area: 1 / 2 / 19 / 12;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 25px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content > p {
    padding: 0;
    margin: 0;
}

.das-link > a:link,
.das-link > a:visited,
.das-link > a:hover,
.das-link > a:active {
  color: white;
}

.sign-button {
    box-sizing: border-box;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  background-color: #0096DB;
  border: none;
  padding: 15px;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 25px;
  cursor: pointer;
}

.signature-preview-container {
    position: relative;
    width: 100%;
    height: 40px;
    background: white;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    cursor: pointer;
}

.signature-preview-container::before {
    display: var(--display-sign-form);
    content: "Sign here\00000a▼";
    width: max-content;
    position: absolute;
    text-align: center;
    left: 50%;
    padding: 15px;
    padding-bottom: 10px;
    background: rgba(0,0,0,0.7);
    border-radius: 3px;
    white-space: pre-line;
    animation: floating 2s ease-in-out infinite;
}

.adopt-signature-preview {
    font-family: 'Cedarville Cursive', cursive;
    color: black;
    font-size: 24px;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.signature-preview-container > img {
    height: 100%;
    image-rendering:unset;
}

.clear-signature-button {
    position: absolute;
    bottom: 100%;
    font-size: 26px;
    left: calc(100% + 10px);
    transform: translateY(100%);
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sign-input {
    position: relative;
}

.input-main {
    width: 100%;
}

.input-container {
    display: flex;
    flex-direction:column;
    width: 100%;
    gap: 5px;
    color: white;
    font-size: 12px;
}

.input-container > input {
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    padding-left: 15px;
    box-sizing: border-box;
}

.floating { 
    animation-name: floating;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function:ease-in-out;
}
 
@keyframes floating {
    0% { transform: translate(-50%,  -50%); }
    50%  { transform: translate(-50%, -60%); }
    100%   { transform: translate(-50%, -50%); }  
}

@media (min-width: $media-min-width) {
    .mobile-quote-container {
        display: none;
    }

    .form-container {
        flex-direction: row;
        column-gap: 10px;
    }

    .form-container > * {
        flex: 1;
    }

    .input-main {
        max-width: 425px;
    }
}

</style>