<template>
    <div class="step-4-section-root" id="step-4-scroll-anchor">
      <!-- <div class="content">
          <p>
            <input type="checkbox" id="decline" v-model="declineGifts" />
            <label for="decline" class="decline-checkbox-label" style="font-size: 12px;">I decline the consumer engagement platform savings and understand by opting out that I am forfeiting on behalf of the dealership up to $13,000 in annual savings.</label>
          </p>
        </div> -->
        <div class="header">
            <div class="header-sub-left">
                    <div class="label">Step Three:</div>
                    <div class="sub-label">Sign below and check the boxes to complete your GM SMRM enrollment with DAS and receive your gifts!  Note there is an additional step that needs to be done in GM Global Connect to complete your enrollment.</div>
                </div>
              <div class="header-sub-right">3</div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      declineGifts: {
        get() {
          return this.$store.state.addonOptOut;
        },
        set(value) {  
          if(value == true) {
            this.$store.commit('ADDON_OPT_OUT');
          }
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .step-4-section-root {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      padding-bottom: 35px;
  }

  .banner-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: white;
    padding-bottom: 15px;
  }
  
  .header {
      display: flex;
      margin-top: 20px;
      column-gap: 10px;
      width: 100%;
  }
  
  .header-sub-left {
      display: flex;
      flex-direction: column;
      flex: 1;
      flex-grow: 6;
      row-gap: 10px;
  }
  
  .header-sub-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-grow: 1;
      color: white;
      text-align: right;
      font-size: 150px;
      opacity: 0.5;
      font-weight: bold;
  }

  .decline-checkbox-label {
    color: white;
  }
  
  .label {
      font-size: 26px;
      font-weight: bold;
      color: white;
  }
  
  
  .sub-label {
      color: #FFF;
      font-weight: bold;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .step-4-checkbox {
    width: 20px;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid rgb(255, 255, 255);
    background: rgba(0,0,0,0.0);
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: #FFF;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(78, 78, 78, 0.2);
  }
  
  @media (min-width: $media-min-width) {

      .decline-checkbox-label {
        color: black;
      }
      .label {
          font-size: 42px;
          margin-top: 20px;
      }
  
      .header {
        margin-top: 50px;
      }
  
      .sub-label {
          font-size: 24px;
      }
  
      .header-sub-right {
        font-size: 200px;
      }
  
      .content { 
        align-items: flex-end;
      }

      [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid grey;
    background: rgba(0,0,0,0.0);
    border-radius: .2em;
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(203, 34, 237, .2);
    -webkit-transition: all .275s;
        transition: all .275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: '✕';
    position: absolute;
    top: .525em;
    left: .18em;
    font-size: 1.375em;
    color: grey;
    line-height: 0;
    -webkit-transition: all .2s;
        transition: all .2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: grey;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* Accessibility */
  [type="checkbox"]:checked:focus + label:before,
  [type="checkbox"]:not(:checked):focus + label:before {
    box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(78, 78, 78, 0.2);
  }
  }
  </style>